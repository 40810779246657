import axios from 'axios';
import ApiService from './ApiService';
export const apiSaveAdAccounts = async (accounts) => {
    return ApiService.fetchData({
        url: 'client/googleAd/save-ad-accounts',
        method: 'post',
        data: { accounts },
    });
};
export const apiGetIntegrationAdAccounts = async (code) => {
    return ApiService.fetchData({
        url: `client/googleAd/google-ad-accounts?code=${code}`,
        method: 'get',
    });
};
export const apiEnableGoogleIntegration = async () => {
    return ApiService.fetchData({
        url: 'client/googleAd/enable-google-ad',
        method: 'post',
    });
};
export const apiDisableGoogleIntegration = async () => {
    return ApiService.fetchData({
        url: 'client/googleAd/disable-google-ad',
        method: 'delete',
    });
};
export const apiGetGoogleAuthUrl = async () => {
    return ApiService.fetchData({
        url: 'client/googleAd/auth-url',
        method: 'get',
    });
};
export const apiGetCurrentAdAccounts = async () => {
    return ApiService.fetchData({
        url: 'client/googleAd/ad-accounts',
        method: 'get',
    });
};
export const apiGetCurrentClientData = async (params) => {
    return await ApiService.fetchData({
        url: 'client/current-client-data',
        method: 'get',
        params,
    });
};

export const apiChangeClientPassword = async (data) => {
    return await ApiService.fetchData({
        url: `client/update-password/`,
        method: 'post',
        data,
    });
};

export const apiChangeClientGooglePassword = async ({idToken , newPassword }) => {
    return await ApiService.fetchData({
        url: `client/update-google-password`,
        method: 'post',
        headers:{
            idToken,
        },
        data:{
            newPassword,
        },
    });
};

export const apiGetBillingSession = async (data) => {
    return await ApiService.fetchData({
        url: `stripe/create-session?stripeCustomerId=${data.stripeCustomerId}`,
        method: 'get',
    });
};

export const apiUpdateClientProfile = async (data) => {
    return await ApiService.fetchData({
        url: `client/update-profile`,
        method: 'patch',
        Headers: {
            Connection: 'keep-alive',
            timeout: 240000,
        },
        data,
    });
};

export const apiIntegrateFacebook = async (data) => {
    return await ApiService.fetchData({
        url: 'client/integerate-facebook',
        method: 'post',
        data,
    });
};

export const apiChangeFacebookStatus = async (data) => {
    return await ApiService.fetchData({
        url: `client/change-facebook-status?company_id=${data.company_id}&fb_active=${data.fb_active}`,
        method: 'post',
    });
};

export const apiGetCompanyClients = async (params) => {
    return await ApiService.fetchData({
        url: 'client/get-company-clients?company_id=' + params.company_id,
        method: 'get',
    });
};

export const apiPostClientInvite = async (data) => {
    return await ApiService.fetchData({
        url: 'client/invite-client',
        method: 'post',
        data,
    });
};

export const apiUpdateClientAccess = async (data) => {
    return await ApiService.fetchData({
        url:
            'client/update-client-access?client_id=' +
            data.clientId +
            '&role=' +
            data.role,
        method: 'patch',
    });
};

export const apiDeleteMember = async (data) => {
    return await ApiService.fetchData({
        url:'client/remove-member',
        method:'patch',
        data,
    })
}

export const apiRegisterFromInvite = async (data) => {
    return await ApiService.fetchData({
        url: 'client/register-invited-client',
        method: 'post',
        data,
    });
};
